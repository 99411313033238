// 
// Extras pages.scss
//


// pricing

.pricing-nav-tabs{
    display: inline-block;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    padding: 4px;
    border-radius: 7px;
    li{
        display: inline-block;
    }
}


.plan-box{
  cursor: pointer;

    .plan-features{
        li{
            padding: 7px 0px;
        }
    }

  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    border-color: $gray-400;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}



// blog

.blog-play-icon{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0px auto;
}