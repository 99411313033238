// 
// _layouts.scss
// 
 body {
     overflow: hidden;
 }

.login_page {
    overflow: hidden !important;
    background-color: white !important;
}

body[data-layout-size="boxed"] {
    
    #layout-wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    #page-topbar {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &[data-sidebar-size="sm"] {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    #page-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

// Scrollable layout

body[data-layout-scrollable="true"] {
    @media (min-width: 992px) {
        #page-topbar, .vertical-menu{
            position: absolute;
        }
    }

    &[data-layout="horizontal"]{
        @media (min-width: 992px) {
            #page-topbar, .topnav{
                position: absolute;
            }
        }
    }
}

#layout-wrapper {
    display: flex;
}


.summarized-vision-group {
    background-color: #f7f8f9;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    min-height: 300px;
    padding: 20px;

    .amount-kpi {
        padding: 9px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 8px;
        border: 1px solid #f0f2f5 !important;
        color: #222020 !important;
        background-color: rgba(236,239,241,1.00) !important;

        span {
            color: rgba(136,153,168,1.00);
        }
    }
}

.max-height-155{
    max-height: 155px !important;
}