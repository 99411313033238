//
// _modal.scss
//

.modal-content {
  box-shadow: $black 0px 4px 8px;
  padding: 15px;
}

.modal {
  /*From Right/Left */
  &.drawer {
    display: flex !important;

    pointer-events: none;
    * {
      pointer-events: none;
    }

    .modal-dialog {
      margin: 0px;
      display: flex;
      flex: auto;
      transform: translate(25%, 0);
      .modal-content {
        border: none;
        border-radius: 0px;
        overflow-y: auto;
        position: absolute;
        min-height: 100%;
        .modal-body {
          overflow: hidden;
        }
        .modal-footer {
          background-color: $white;
        }
      }
    }

    .gravame {
      min-width: 75%;
    }

    &.show {
      pointer-events: auto;
      * {
        pointer-events: auto;
      }
      .modal-dialog {
        transform: translate(0, 0);
      }
    }

    &.right-align {
      flex-direction: row-reverse;

      .modal-content {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    &.left-align {
      &:not(.show) {
        .modal-dialog {
          transform: translate(-25%, 0);
        }
      }
    }
  }
}

.modal-content-scroll {
  padding: 15px 0 15px 15px;

  & .modal-header {
    padding-right: 30px;
  }
  & .modal-body {
    padding-right: 30px;
  }
  & .modal-footer {
    padding-right: 25px;
    padding-bottom: 25px;
  }
}
