.swal2-styled{

  &.swal2-confirm {
    font-family: inherit !important;
    line-height: 1.15 !important;
    height: 33px !important;
    appearance: none !important;
    border: 0 !important;
    outline: none !important;
    cursor: pointer !important;
    position: relative !important;
    align-items: center !important;
    border-radius: 8px !important;
    display: inline-flex !important;
    justify-content: center !important;
    padding: 14px 48px !important;
    text-align: center !important;
    background-color: $primary !important;
    border-bottom: 1px solid #002259 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  &.swal2-cancel {
    font-family: inherit !important;
    line-height: 1.15 !important;
    height: 33px !important;
    appearance: none !important;
    border: 0 !important;
    outline: none !important;
    cursor: pointer !important;
    position: relative !important;
    align-items: center !important;
    border-radius: 8px !important;
    display: inline-flex !important;
    justify-content: center !important;
    padding: 14px 48px !important;
    text-align: center !important;
    background-color: #f0f2f6 !important;
    color: black !important;
    border-bottom: 1px solid #d6ddea !important;

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background-color: #f0f2f6 !important;
    }
  }
}