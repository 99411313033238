//
// _table.scss
//

.table {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid $table-border-color;
  border-radius: 0.25rem;

  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $white;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  border: 1px solid white !important;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.colunm-h-name-wrap {
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
}
