// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

.btn {
  font-family: inherit;
  line-height: 1.15;
  height: 33px;
  margin: 0px;
  appearance: none;
  border: 0px;
  outline: none;
  cursor: pointer;
  position: relative !important;;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  display: inline-flex !important;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 14px 48px;
  text-align: center;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-outline-dark, .btn-dark{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: $light;
  }
}

// Button Border
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    border-bottom: 1.5px solid darken($value, 7.5%);
  }
}

//.btn-secondary {
//  background: rgb(220, 221, 221);
//  color: #626262 !important;
//  border: none;
//}

.btn-select {
  border-radius: 8px;
  border: 1px solid #f0f2f5;
  color: $primary;
  background-color: #f0f2f5;
  margin-top: 1px !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: 42px;
  display: flex !important;
  align-items: center;
  justify-content: start;
  text-align: start;

  &:after{
    position: absolute;
    content: '\F0142';
    font-family: "Material Design Icons";
    right: 20px; /*rtl: -4px */
    font-size: 18px;
    color: $primary;
  }

  &:hover {
    background-color: #eaeaea !important;
  }
}

.btn-search-icon {
  border-radius: 8px;
  border: 1px solid #f0f2f5 !important;
  color: $primary !important;
  background-color: #f0f2f5 !important;
  margin-top: 1px !important;
  width: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  height: 38px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-without-class {
  padding: 0 5px 0 5px;

  :focus {
    border: none;
  }
  :hover {
    border: none;
  }

}

.btn-tr-clicable {
  cursor: pointer;


}

