// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 20px;
}

.card-height-100 {
  height: calc(100% - #{$grid-gutter-width});
}

.card-product-select {
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;

  table {
    padding: 0;
    margin: 0;

    &.selected td {
      border: none
    }
  }

  &.selected {
    border: none
  }

  .form-check-input{
    font-size: 16px;
  }

  i {
    color: $body-color
  }

  label {
    cursor: inherit;
  }
}

.static-label {
  color: $card-title-desc;
  margin: 0 !important;
  padding: 0 !important;
}