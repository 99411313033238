// 
// Widgets.scss
// 

.action-bar-fly {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 85%;

    .action-bar-fly-wrapper {
        background: #fff;
        height: 85px;
        border-radius: 5px;
        box-shadow: 0 15px 50px rgba($black , .30);

        .action-bar-fly-content {
            padding: 15px 30px 10px 30px;
        }
    }
}
