//
// Forms.scss
//




[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
 &::placeholder{
   text-align: left /*rtl: right*/;
 }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;
}

.form-check-active {
  border-radius: 8px;
  background-color: #f0f2f5;
  padding: 10px 10px 10px 40px;
}

.form-check-inactive {
  border-radius: 8px;
  padding: 10px 10px 10px 40px;
}


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;
    .form-check-input{
      float: right;
      margin-left: 0;
      margin-right: $form-check-padding-start * -1;
    }
  
    .form-check-label{
      display: block;
    }
}

  

// checkbox

.form-checkbox-outline{
  
  .form-check-input{
    border-width: 2px;
    background-color: $card-bg;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;

      &[type=checkbox]{
        background-image: none;
        
      }

      &:after{
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px; /*rtl: -4px */
        font-size: 16px;
        color: $dark;  
      }
    }
  }
}

// radio

.form-radio-outline{
  .form-check-input{
    background-color: $card-bg;
    position: relative;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;
  
      &[type=checkbox]{
        background-image: none;
        
      }
  
      &:after{
        position: absolute;
        content: '';
        top: 3px!important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color}{
    .form-check-input{
      &:checked{
        background-color: $value !important;
        border-color: $value !important;
      }
    }
  }

  .form-radio-#{$color}{
    .form-check-input{
      &:checked{
        border-color: $value !important;
        background-color: $value !important;
        &:after{
          background-color: $value !important;
        }
      }
    }
  }
}

.form-check-label{
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md{
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;
  .form-check-input{
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label{
    vertical-align: middle;
  }
}


.form-switch-lg{
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
  .form-check-input{
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text{
  margin-bottom: 0px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid $input-border-color;
  color: rgb(34, 32, 32);
  background-color: $input-bg;
  margin-top: 1px;
  height: 42px;

  &:focus{
    border: 1px solid $input-focus-border-color;
  }
}
.form-label {
  color: $gray-light !important;
  font-size: 1em !important;
  margin-left: 2px !important;
}