.checkout-page {
    overflow-x: hidden;
    height: 100vh;

    background-color: $white;

    .checkout-ticket-title {
        font-size: 20px;
        letter-spacing: normal;
        font-weight: 500;
        color: #ffffff9e;
    }

    .checkout-ticket-amount {
        font-size: 40px;
        letter-spacing: normal;
        font-weight: 700;
    }

    .checkout-ticket-installments {
        font-size: 20px;
        color: #ffffff9e;
    }

    .checkout-ticket-seller {
        font-size: 20px;
        font-weight: 600;
    }

    .checkout-ticket-info {
        font-size: 16px;
        font-weight: 600;
        color: #ffffff9e;
    }
    .checkout-buyer-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0px;
        margin: 0px;
    }
}
