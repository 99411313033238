#home-menu {

  &.page-content {
    background: white;
    // min-height: 1150px;
  }

  .item-menu-group {
    padding-right: 5;

    & div {
      display: grid;
      cursor: pointer;
      text-align: center;

      & span {
        color: $white-light;
        font-weight: $font-weight-semibold;
        font-size: 1.3em;
      }

      & :hover {
        opacity: 0.8;
      }
    }
  }

  .card-notifications {
    margin-top: -30px,;
    height: 1150px;
    margin-right: -20px;
    background-color: $white;
    padding: 40px;
  }

  .page-menu {
    margin: 0;

    li {
      display: block;
      width: 100%;
    }

    .page-menu-title {
      letter-spacing: .05em;
      pointer-events: none;
      cursor: default;
      font-size: 1.3em;
      color: $white-light;
      font-weight: $font-weight-semibold;
      margin-bottom: 15px;

      & i {
        font-size: 1.4rem;
        margin-right: 10px;
      }
    }

    .page-menu-item {
      margin-bottom: 15px;

      & span{
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        color: $white-light;
        font-size: 1.1em;
      }

      & i {
        color: $white-light;
        margin-right: 10px;
        font-size: 1.4rem;
      }

    }

    .mm-collapse {
      display: none;

      &:not(.mm-show) {
        display: none;
      }

      &.mm-show {
        display: block
      }
    }
  }

}



.alert-body {
  line-height: 1.2px;
  margin: 0px;
  white-space: pre-line;
  color: rgb(34, 32, 32);
  font-weight: 400;
  font-size: 1em;
}
.alert-icon {
  color: rgb(104,109, 113);
  font-size: 3rem
}

.menu-item-img {
  box-shadow:0 5px 28px rgba($black, .10);
  border-radius: 23%;
  width: 105px;
  height: 105px;

  & img {
    height: 100%;
    width: 100%;
    block-size: 100%;
    inline-size: 100%;

  }
}

