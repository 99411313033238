.toast-container {
  z-index: 9999;
}

// react-toastify

.Toastify__toast {
  background-color: $white;
  border-color: $toast-border-color;
  font-family: $font-family-sans-serif;
  font-size: $toast-font-size;
  line-height: 1.25rem;
}
