//
// select.scss
//
.select2-floating > .select2-selection__control{
  height: calc(3.5rem + 2px);
}
.select-modal {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>');
  background-repeat: no-repeat;
  background-position: right calc(.375em + .235rem) center;
  background-size: calc(.75em + .47rem) calc(.75em + .47rem);
}

.select2-floating-invalid > .select2-selection__control{
  height: calc(3.5rem + 2px);

  border-color: #dc3545;
  box-shadow: 0 0 0 0.10rem rgba(220, 53, 69, 0.25);
}