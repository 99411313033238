// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.auth-logo{
    .auth-logo-light{
        display: $display-none;
    }

    .auth-logo-dark{
        display: $display-block;
    }

    .auth-logo-light, .auth-logo-dark{
        margin: 0px auto;
    }
}

.bg-auth{
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: $primary;
    box-shadow: #000 0px 4px 8px;

    & .bg-auth-title{
        color: white;
        font-size: 4em;
        font-weight: 900;
        margin-top: 80px
    }

    & .bg-auth-detail{
        color: #ffffffab;
        font-size: 24px;
        letter-spacing: 2px;
    }

}

.login-page {
    background-color: $white;
}

.dropdown-user-logged {
    .btn-secondary {
        color: inherit !important;
        background-color: inherit !important;
        border: inherit !important;
    }

    & i {
        font-size: 20px;
    }
}