.badge-md {
  padding: 10px !important;
  line-height: 1.25;
  white-space: pre-line;
  font-weight: 600;
  font-size: 0.875em;
}
.static-label {
  color: $card-title-desc;
}
.h-900 {
  height: 900px;
}

.h-1200 {
  height: 1200px;
}

.logo-sm-demo {
  position: relative;
  background-image: url("../../../images/financial-agent/logo-sm-35262759000127.png");
  background-position: center;
  background-size: cover;
  height: 30px;

  margin-bottom: 40px;
  margin-top: 10px;
  margin-left: 20px;
}

.logo-sm-71027866000134 {
  margin-bottom: 40px;
}
.logo-sm-35262759000127 {
  margin-bottom: 40px;
  margin-left: 22px;
}
.logo-sm-44353163000123 {
  margin-bottom: 40px;
  margin-left: 22px;
}
.logo-sm-default {
  margin-bottom: 40px;
  margin-left: 22px;
}

.z-0 {
  z-index: 0 !important;
}

.z-1000 {
  z-index: 1000 !important;
}

.rec-plan-box {
  border: 1px solid #f0f2f5;
  border-radius: 8px;

  .product {
    padding-top: 5px;
  }
}

.bg-primary {
  background-color: $primary !important;
  color: white !important;
}

.hr-line {
  border-top: 1px solid rgb(187, 187, 187);
}

#newGravameForm {
  .form-group {
    .select2-form-disabled {
      cursor: not-allowed;
    }
    input.gravame:disabled {
      cursor: not-allowed;
    }
    :disabled {
      cursor: not-allowed;
    }
  }
}
